import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Heading,
  Button,
  Box,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import EmbeddingsOptions from "../embeddings/EmbeddingsOptions";

const FaissSettings = () => {
  const dispatch = useDispatch();
  //set settings states as user inputs right away
  const store_embeddings_provider = useSelector(
    (state) => state.store_embeddings_provider
  );

  //Handle any settings input changes
  const handleSettingsInput = (e) => {
    if (e.target.id === "data-store-embeddings-provider") {
      dispatch({
        type: "setDataStoreEmbeddingsProviderSettings",
        store: document.getElementById("data-store-embeddings-provider").value,
      });
    }
  };

  return (
    <FormControl>
      <Box fontSize="sm" marginBottom={"4"}><strong>Disclaimer:</strong> Faiss instances are deleted 1 hour after you store data in it. To keep data persistent please use another provider.</Box>
      <FormControl isRequired>
        <FormLabel fontSize="14px">Embeddings</FormLabel>
        <Select
          placeholder="Select Embedding Provider"
          size="sm"
          borderRadius="10"
          marginBottom="1"
          id="data-store-embeddings-provider"
          value={store_embeddings_provider}
          onChange={handleSettingsInput}
        >
          <option value="OpenAI">OpenAI</option>
          <option value="Cohere">Cohere</option>
        </Select>
        <EmbeddingsOptions provider={store_embeddings_provider} />
      </FormControl>
    </FormControl>
  );
};

export default FaissSettings;
