import {
  List,
  ListItem,
  ListIcon,
  Image,
  Box,
  Divider,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { GrDocumentUpload } from "react-icons/gr";
import { BiBot } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { GoBook } from "react-icons/go";

const Sidebar = () => {
  return (
    <List color="black" fontSize="18px" spacing={4}>
      <Box align="center" paddingTop="4" px="12">
        <Image h="30px" src="/logo_text.svg" />
      </Box>
      <ListItem paddingLeft={{ base: "5", lg: "30px" }}>
        <NavLink to="/">
          <ListIcon as={BiBot} color="black" paddingBottom="3px" />
          Chat
        </NavLink>
      </ListItem>
      <ListItem paddingLeft={{ base: "5", lg: "30px" }}>
        <NavLink to="datastore">
          <ListIcon as={GrDocumentUpload} color="black" paddingBottom="3px" />
          Store Data
        </NavLink>
      </ListItem>
      <ListItem paddingLeft={{ base: "5", lg: "30px" }}>
        <NavLink to="settings">
          <ListIcon as={FiSettings} color="black" paddingBottom="3px" />
          Settings
        </NavLink>
      </ListItem>
      <ListItem paddingLeft={{ base: "5", lg: "30px" }}>
        <NavLink to="docs">
          <ListIcon as={GoBook} color="black" paddingBottom="2px" />
          Docs
        </NavLink>
      </ListItem>
    </List>
  );
};

export default Sidebar;
