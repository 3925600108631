import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { React, useState, useEffect } from "react";

const PromptVariable = ({ variable }) => {
  const dispatch = useDispatch();
  const promptVariables = useSelector((state) => state.prompt_variables);
  const [type, setType] = useState(variable.type);
  const generatorProvider = useSelector((state) => state.generator_provider);


  useEffect(() => {
    if (type !== variable.type)
    {
      setType(variable.type);
    }
  }, [variable.type]);

  const handleChange = (e) => {
    const newType = e.target.value;
    setType(newType);
    const updatedPromptVariables = promptVariables.map((v) => {
      if (v.name === variable.name) {
        return { ...v, type: newType };
      } else {
        return v;
      }
    });
    dispatch({ type: "setPromptVariables", store: updatedPromptVariables });
  };

  return (
    <FormControl px="3" paddingTop="1">
      <FormLabel fontSize="14px">{variable.name}</FormLabel>
      <Select
        size="sm"
        borderRadius="10"
        marginBottom="1"
        value={type}
        onChange={handleChange}
      >
        <option value="Input">Human Input</option>
        {generatorProvider != 'OpenAI_ChatGPT' && <option value="Memory">Memory</option>}
        <option value="Context">Context</option>
      </Select>
    </FormControl>
  );
};

export default PromptVariable;
