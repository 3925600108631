import React from 'react'
import { SimpleGrid,
Heading,
Divider,
Box,
GridItem, 
HStack} from '@chakra-ui/react'
import DataRetrieveSettings from '../components/DataRetrieveSettings'
import DataInput from '../components/DataInput'
import ChunkSettings from '../components/ChunkSettings'
import InputSettings from '../components/datastore_settings/InputSettings'
import ModelList from '../components/models/ModelList'

const DataStore = () => {
  return (
    <SimpleGrid columns="2" templateColumns="repeat(3, 1fr)">
        <Box height='100vh' bg="white" overflowY={'auto'}>
          <HStack spacing="4">
          <Heading as='h4' size='md' p ="4" flex = {1}>
                Store Embeddings
          </Heading>
          <ModelList />
          <div></div>
          </HStack>
            <Divider orientation='horizontal' />
            <DataRetrieveSettings />
            <ChunkSettings />
        </Box>
        <GridItem colSpan={2}>
            <DataInput />
        </GridItem>
    </SimpleGrid>
  );
};

export default DataStore;
