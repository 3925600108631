import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Heading,
  Button,
  Box,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import EmbeddingsOptions from "../embeddings/EmbeddingsOptions";

const InputSettings = () => {
  const dispatch = useDispatch();
  //set settings states as user inputs right away
  const store_input_type = useSelector((state) => state.store_input_type);

  //Handle any settings input changes
  const handleSettingsInput = (e) => {
    dispatch({ type: "setDataStoreInputSettings", store: e.target.value });
    //reset text redux when different type is selected
    dispatch({ type: "setDataStoreData", store: "" });
  };

  return (
    <FormControl>
      <FormLabel fontSize="14px">Data Source</FormLabel>
      <Select
        size="sm"
        borderRadius="10"
        marginBottom="1"
        maxW="50%"
        value={store_input_type}
        onChange={handleSettingsInput}
      >
        <option value="Text">Text</option>
        <option value="Youtube">Youtube Transcript</option>
      </Select>
    </FormControl>
  );
};

export default InputSettings;
