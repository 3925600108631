import {
  FormControl,
  FormLabel,
  Button,
  Select,
  HStack,
  Text,
  IconButton,
  Tooltip,
  Stack,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { React, useState, useEffect } from "react";
import { fetchWithTimeout, decrypt } from "../../utils/SettingsUtil";
import { SmallCloseIcon, CheckIcon } from "@chakra-ui/icons";
import { AiOutlineSave } from "react-icons/ai";
import { JsonBuild } from "../../utils/SettingsUtil";

const Model = ({ variable, refreshModelList }) => {
  //redux
  const openaiKey = useSelector((state) => state.openaikey);
  const pineconeKey = useSelector((state) => state.pineconekey);
  const cohereKey = useSelector((state) => state.coherekey);
  const storeProvider = useSelector((state) => state.store_provider);
  const storeIndexName = useSelector((state) => state.store_index_name);
  const storeNameSpace = useSelector((state) => state.store_namespace);
  const storeEmbeddingsProvider = useSelector(
    (state) => state.store_embeddings_provider
  );
  const storeEmbeddings = useSelector((state) => state.store_embeddings);
  const generatorEngine = useSelector((state) => state.generator_engine);
  const generatorProvider = useSelector((state) => state.generator_provider);
  const generatorTemperature = useSelector(
    (state) => state.generator_temperature
  );
  const generatorMaxtoken = useSelector((state) => state.generator_maxtoken);
  const promptTemplate = useSelector((state) => state.prompt_template);
  const inputVariables = useSelector((state) => state.prompt_variables);
  const uuid = useSelector((state) => state.uuid);
  const logged_in_user = useSelector((state) => state.logged_in_user);
  const chunk_settings_method = useSelector(
    (state) => state.chunk_settings_method
  );
  const chunk_settings_overlap = useSelector(
    (state) => state.chunk_settings_overlap
  );
  const chunk_settings_size = useSelector((state) => state.chunk_settings_size);
  const chunk_settings_separator = useSelector(
    (state) => state.chunk_settings_separator
  );
  const selected_model = useSelector((state) => state.selected_model);
  const system_message = useSelector((state) => state.system_content);

  const dispatch = useDispatch();
  const model_list = useSelector((state) => state.model_list);

  const [buttonColor, setButtonColor] = useState("white");
  useEffect(() => {
    if (selected_model === variable.user_config.model_id) {
      setButtonColor("#DCDCDC");
    } else {
      setButtonColor("white");
    }
  }, [selected_model]);

  const handleLoad = (e) => {
    e.preventDefault();
    //Datastore options
    dispatch({
      type: "setDataStoreProviderSettings",
      store: variable.datastore_config.type,
    });
    dispatch({
      type: "setDataStoreIndexNameSettings",
      store: variable.datastore_config.index_name,
    });
    dispatch({
      type: "setDataStoreNameSpaceSettings",
      store: variable.datastore_config.namespace,
    });
    //Embeddings options
    dispatch({
      type: "setDataStoreEmbeddingsProviderSettings",
      store: variable.embeddings_config.type,
    });
    dispatch({
      type: "setDataStoreEmbeddingsSettings",
      store: variable.embeddings_config.engine_name,
    });
    //Chunk Options
    dispatch({
      type: "setChunkSettings.method",
      store: variable.text_splitter_config.type,
    });
    dispatch({
      type: "setChunkSettings.size",
      store: variable.text_splitter_config.chunk_size,
    });
    dispatch({
      type: "setChunkSettings.overlap",
      store: variable.text_splitter_config.chunk_overlap,
    });
    //LLM Options
    dispatch({
      type: "setGeneratorProviderSettings",
      setting: variable.llm_config.type,
    });
    dispatch({
      type: "setGeneratorEngineSettings",
      setting: variable.llm_config.engine_name,
    });
    dispatch({
      type: "setGeneratorTemperatureSettings",
      setting: variable.llm_config.temperature,
    });
    dispatch({
      type: "setGeneratorMaxTokenSettings",
      setting: variable.llm_config.max_tokens,
    });
    //Prompt Options
    dispatch({
      type: "setPromptTemplate",
      store: variable.prompt_config.prompt_template,
    });
    dispatch({
      type: "setPromptVariables",
      store: variable.prompt_config.input_variables,
    });
    dispatch({
      type: "setSystemContent",
      store: variable.prompt_config.system_message,
    });
    //API Keys - decode keys
    const d_open_ai = decrypt(variable.api_config.open_ai);
    const d_pinecone = decrypt(variable.api_config.pinecone);
    const d_cohere = decrypt(variable.api_config.cohere);
    dispatch({ type: "setOpenAiApiSettings", key: d_open_ai });
    dispatch({ type: "setPineconeApiSettings", key: d_pinecone });
    dispatch({ type: "setCohereApiSettings", key: d_cohere });

    dispatch({ type: "setModel", store: variable.user_config.model_id });
  };

  const handleUpdate = async () => {
    const new_model_name = variable.user_config.model_id;

    const json_string = JsonBuild(
      {
        generator_provider: generatorProvider,
        generator_temperature: generatorTemperature,
        generator_max_tokens: generatorMaxtoken,
        generator_engine_name: generatorEngine,
        datastore_provider: storeProvider,
        datastore_index_name: storeIndexName,
        datastore_name_space: storeNameSpace,
        embeddings_provider: storeEmbeddingsProvider,
        embeddings_engine_name: storeEmbeddings,
        input_variables: inputVariables,
        prompt_template: promptTemplate,
        system_message: system_message,
        openai_api_key: openaiKey,
        pinecone_api_key: pineconeKey,
        cohere_api_key: cohereKey,
        text_splitter_type: chunk_settings_method,
        text_splitter_separator: chunk_settings_separator,
        text_splitter_size: chunk_settings_size,
        text_splitter_overlap: chunk_settings_overlap,
        logged_in_user: logged_in_user,
        new_model_name: new_model_name,
      },
      "save_model"
    );

    //local - '/userdata/save_model_detail'
    //hosted process.env.REACT_APP_USER_DATA_SAVE_MODEL_ROUTE
    var res;
    try {
      res = await fetchWithTimeout(
        process.env.REACT_APP_USER_DATA_SAVE_MODEL_ROUTE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: process.env.REACT_APP_JWT_TOKEN,
          },
          body: JSON.stringify(json_string),
        }
      );
    } catch (error) {
      //catch timeout
      if (error.name === "AbortError") {
        alert("Timeout: Request took longer than 30 seconds to respond");
        return false;
      }
      return false;
    }
    //update modellist to have newly added model
    refreshModelList();
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    //send delete call to api
    var res;
    try {
      res = await fetchWithTimeout(
        process.env.REACT_APP_USER_DATA_DELETE_MODEL_ROUTE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: process.env.REACT_APP_JWT_TOKEN,
          },
          body: JSON.stringify({
            user_id: variable.user_config.user_id,
            model_id: variable.user_config.model_id,
          }),
        }
      );
    } catch (error) {
      //catch timeout
      if (error.name === "AbortError") {
        alert("Timeout: Request took longer than 30 seconds to respond");
        return false;
      }
    }

    //update model_list
    const newList = model_list.filter(
      (item) => item.user_config.model_id !== variable.user_config.model_id
    );
    dispatch({ type: "setModels", store: newList });
  };

  return (
    <>
      <Stack
        as="button"
        spacing={"2"}
        width="100%"
        paddingTop="1"
        paddingBottom="1"
        marginTop={"2"}
        border="1px"
        borderColor="gray.500"
        borderRadius={"8"}
        direction="row"
        style={{ backgroundColor: buttonColor }}
      >
        <Text fontSize="sm" flex={1} onClick={handleLoad}>
          {variable.user_config.model_id}
        </Text>
        {buttonColor === "#DCDCDC" && (
          <Tooltip label="Update model">
            <IconButton
              size="xs"
              variant="outline"
              icon={<AiOutlineSave />}
              onClick={handleUpdate}
            ></IconButton>
          </Tooltip>
        )}
        <Tooltip label="Delete model">
          <IconButton
            size="xs"
            variant="outline"
            icon={<SmallCloseIcon />}
            onClick={handleDelete}
          ></IconButton>
        </Tooltip>
        <div></div>
      </Stack>
    </>
  );
};

export default Model;
