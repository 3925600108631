import {
  Heading,
  Box,
  Text,
  Divider,
  Flex,
  IconButton,
  Input,
  Button,
  HStack,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";

import { RepeatIcon } from "@chakra-ui/icons";

import { IoSend } from "react-icons/io5";
import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ChatBubble from "./ChatBubble";
import { json } from "react-router-dom";
import PromptEditor from "./prompts/PromptEditor";
import { JsonBuild, fetchWithTimeout } from "../utils/SettingsUtil";
import LoginButton from "./auth0/LoginButton";
import LogoutButton from "./auth0/LogoutButton";
import ModelList from "./models/ModelList";
import { auth } from "../firebase";
import ProfileMenu from "./menus/ProfileMenu";

const ChatWindow = () => {
  const dispatch = useDispatch();
  //bring all settings from redux
  const openaiKey = useSelector((state) => state.openaikey);
  const pineconeKey = useSelector((state) => state.pineconekey);
  const cohereKey = useSelector((state) => state.coherekey);
  const storeProvider = useSelector((state) => state.store_provider);
  const storeIndexName = useSelector((state) => state.store_index_name);
  const storeNameSpace = useSelector((state) => state.store_namespace);
  const storeEmbeddingsProvider = useSelector(
    (state) => state.store_embeddings_provider
  );
  const storeEmbeddings = useSelector((state) => state.store_embeddings);
  const generatorEngine = useSelector((state) => state.generator_engine);
  const generatorProvider = useSelector((state) => state.generator_provider);
  const generatorTemperature = useSelector(
    (state) => state.generator_temperature
  );
  const generatorMaxtoken = useSelector((state) => state.generator_maxtoken);
  const chatlog = useSelector((state) => state.chatlog);
  const promptTemplate = useSelector((state) => state.prompt_template);
  const inputVariables = useSelector((state) => state.prompt_variables);
  const uuid = useSelector((state) => state.uuid);
  const logged_in_user = useSelector((state) => state.logged_in_user);
  const selected_model = useSelector((state) => state.selected_model);
  const humanPrefix = useSelector((state) => state.human_prefix);
  const aiPrefix = useSelector((state) => state.ai_prefix);
  const systemContent = useSelector((state) => state.system_content);

  //message - message log states
  const [input, setInput] = useState("");
  const [chatLog, setChatLog] = useState(chatlog);
  //message loading state
  const [loadingMessage, setLoadingMessage] = useState(false);

  //Handle Chat Message input
  async function handleSubmit(e) {
    e.preventDefault();

    if (loadingMessage) {
      return false;
    }

    const inputMessage = `${input}`;
    if (inputMessage === "") {
      alert("Cannot Submit Empty Message");
      return false;
    }

    //construct conversation history that is readable as part of prompt
    var messageLog = [];
    var prefixLog = [];
    for (let chat of chatLog) {
      var label = "";
      var prefix_label = "";
      if (chat.sender === "Bot") {
        label = aiPrefix;
        prefix_label = "assistant";
      } else {
        label = humanPrefix;
        prefix_label = "user";
      }

      messageLog.push("\n" + label + ": " + chat.message);
      prefixLog.push({ role: prefix_label, content: chat.message });
    }

    setLoadingMessage(true);

    const json_string = JsonBuild(
      {
        generator_provider: generatorProvider,
        generator_temperature: generatorTemperature,
        generator_max_tokens: generatorMaxtoken,
        generator_engine_name: generatorEngine,
        datastore_provider: storeProvider,
        datastore_index_name: storeIndexName,
        datastore_name_space: storeNameSpace,
        embeddings_provider: storeEmbeddingsProvider,
        embeddings_engine_name: storeEmbeddings,
        input_variables: inputVariables,
        prompt_template: promptTemplate,
        human_prefix: humanPrefix,
        ai_prefix: aiPrefix,
        system_content: systemContent,
        prefixLog: prefixLog,
        message: inputMessage,
        conv_history: messageLog,
        openai_api_key: openaiKey,
        pinecone_api_key: pineconeKey,
        cohere_api_key: cohereKey,
        uuid: uuid,
      },
      "data_chat"
    );
    if (!json_string) {
      setLoadingMessage(false);
      return false;
    }

    let chatLogNew = [...chatLog, { message: inputMessage, sender: "User" }];
    setChatLog(chatLogNew);
    //this saves chatlog to redux even if fastapi does not return a message
    dispatch({ type: "setChatLog", chatlog: chatLog });
    await setInput("");

    // '/chat/chat_with_data' - localhost
    // process.env.REACT_APP_DATA_CHAT_ROUTE - deployment
    var res;
    try {
      res = await fetchWithTimeout(process.env.REACT_APP_DATA_CHAT_ROUTE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_JWT_TOKEN,
        },
        body: JSON.stringify(json_string),
      });
    } catch (error) {
      setLoadingMessage(false);
      //catch timeout
      if (error.name === "AbortError") {
        alert("Timeout: Request took longer than 30 seconds to respond");
        return false;
      }
    }

    //catch any 500 error before trying to access its JSON response
    if (res.status === 500) {
      setLoadingMessage(false);
      alert("Server Unavailable: Please try again later");
      return false;
    }

    const data = await res.json();

    if (res.status != 200) {
      setLoadingMessage(false);
      alert(data.detail);
      return false;
    }

    let chatLogNew2 = [
      ...chatLogNew,
      {
        message: `${data.data.message}`,
        sender: "Bot",
        context: `${data.data.closest_context}`,
      },
    ];
    setChatLog(chatLogNew2);
    //set redux chatlog
    dispatch({ type: "setChatLog", chatlog: chatLogNew2 });

    await setInput("");
    setLoadingMessage(false);
    return true;
  }

  function handleClearChat() {
    setChatLog([]);
    dispatch({ type: "setChatLog", chatlog: [] });
  }

  //scroll all the way down
  function scrollDown() {
    const element2 = document.getElementById("chat-window-scroll");
    element2.scrollTop = 99999999999;
  }

  useEffect(() => {
    scrollDown();
  }, [chatLog]);

  //Login + Logout listener
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((newUser) => {
      flipLoginState(newUser);
    });
    return unsubscribe;
  }, [dispatch]);

  function flipLoginState(newUser) {
    dispatch({ type: "setLogin", store: newUser });
    return;
  }

  return (
    <Flex w="full" h="100vh" flexDirection="column">
      <HStack spacing={"4"}>
        <HStack p="4" flex={1}>
          <Heading as="h4" size="md">
            Chat
          </Heading>
          <Text> {" > " + selected_model}</Text>
        </HStack>
        <Button
          size="xs"
          as="a"
          href="https://forms.gle/WKSSYFBamo7s4i1J9"
          colorScheme={"pink"}
          target="_blank"
        >
          Request Feature
        </Button>
        <PromptEditor />
        <Tooltip label="Clear Chat">
          <IconButton
            size="sm"
            onClick={handleClearChat}
            icon={<RepeatIcon />}
          ></IconButton>
        </Tooltip>
        <ProfileMenu />
        <div></div>
      </HStack>
      <Divider orientation="horizontal" />
      <Flex
        overflowY="auto"
        px={6}
        flex={1}
        borderLeft="1px"
        borderLeftColor="gray.200"
        flexDirection="column"
        id="chat-window-scroll"
      >
        {chatLog.map((message, index) => (
          <ChatBubble key={index} message={message} />
        ))}
      </Flex>
      <Flex
        pl={3}
        pr={2}
        py={1}
        borderTopColor="gray.200"
        borderTopWidth="1px"
        borderLeft="1px"
        borderLeftColor="gray.200"
      >
        <Input
          variant="unstyled"
          placeholder="Type your message"
          size="sm"
          id="message-input"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSubmit(e);
            }
          }}
        />
        {loadingMessage ? (
          <IconButton colorScheme="pink" variant="ghost" icon={<Spinner />} />
        ) : (
          <IconButton
            colorScheme="pink"
            aria-label="Send message"
            variant="ghost"
            onClick={handleSubmit}
            icon={<IoSend />}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ChatWindow;
