import { React, useState, useEffect, useRef } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Input,
  Textarea,
  FormControl,
  Heading,
  IconButton,
  Tooltip,
  HStack,
  FormLabel,
} from "@chakra-ui/react";
import PromptVariable from "./PromptVariable";
import { useSelector, useDispatch } from "react-redux";
import { EditIcon } from "@chakra-ui/icons";

const SystemMessage = () => {
  const dispatch = useDispatch();
  const systemContent = useSelector((state) => state.system_content);
  const handleSystemContextChange = (e) => {
    dispatch({ type: "setSystemContent", store: e.target.value });
  };

  return (
    <>
      <Tooltip label="The system message helps set the behavior of the assistant.">
        <Heading as="h4" size="sm" marginBottom="2">
          System Message
        </Heading>
      </Tooltip>
      <Textarea
        size="sm"
        borderRadius={8}
        h="20%"
        value={systemContent}
        onChange={handleSystemContextChange}
      />
    </>
  );
};

export default SystemMessage;
