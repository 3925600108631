import { Grid, GridItem } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

// components
import Sidebar from "./Sidebar";

export default function RootLayout() {
  return (
    <Grid templateColumns="repeat(6, 1fr)" bg="white">
      {/* sidebar */}
      <GridItem
        as="aside"
        colSpan={{ base: 6, lg: 1, xl: 1 }}
        bg="#FAFAFA"
        minHeight={{ lg: "100vh" }}
        /*p={{ base: '20px', lg: '30px' }}*/
      >
        <Sidebar />
      </GridItem>

      {/* main content & navbar */}
      <GridItem colSpan={{ base: 6, lg: 5, xl: 5 }}>
        <Outlet />
      </GridItem>
    </Grid>
  );
}
