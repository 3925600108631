import React from "react";
import { auth } from "../../firebase";
import { Button, Text } from "@chakra-ui/react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const LogoutButton = () => {
  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Text
      align="center"
      size="md"
      width="100%"
      as="button"
      onClick={handleLogout}
    >
      Sign Out
    </Text>
  );
};

export default LogoutButton;
