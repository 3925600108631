import React from "react";
import { Text, Box, Heading, Divider, VStack } from "@chakra-ui/react";
import PlaygroundInstructions from "../components/docs/PlaygroundInstructions";
import { docsWelcomeTitle } from "../utils/Messages";

const Docs = () => {
  return (
    <Box height="100vh" bg="white" overflowY={"auto"}>
      <Heading as="h4" size="md" p="4">
        Documentation
      </Heading>
      <Divider orientation="horizontal" />
      <Heading as="h4" size="md" margin="4">
        Getting Started
      </Heading>
      <Text margin="4">{docsWelcomeTitle}</Text>
      <VStack>
        <iframe
          width="550"
          height="325"
          title="Data-Playground Walkthrough"
          src="https://www.youtube.com/embed/sH1Uh-n93E0"
          allowFullScreen
        />
      </VStack>
      <PlaygroundInstructions />
    </Box>
  );
};

export default Docs;
