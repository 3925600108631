import { configureStore } from "@reduxjs/toolkit";
import { createStore } from "redux";
import { v4 as uuidv4 } from "uuid";

const settings = {
  //set these to the default value of settings
  openaikey: "",
  pineconekey: "",
  coherekey: "",
  store_provider: "Pinecone",
  store_input_type: "Text",
  store_index_name: "",
  store_namespace: "",
  store_embeddings_provider: "OpenAI",
  store_embeddings: "",
  store_data: "",
  generator_provider: "OpenAI",
  generator_engine: "",
  generator_temperature: 0.7,
  generator_maxtoken: 128,
  prompt_template:
    "You are a chatbot having a conversation with a human.\n\nGiven the following extracted parts of a long document and a question, create a final answer.\n\n{context}\n\n{chat_logs}\nHuman: {message}\nAI:",
  prompt_variables: [
    { type: "Context", name: "context" },
    { type: "Memory", name: "chat_logs" },
    { type: "Input", name: "message" },
  ],
  human_prefix: "Human",
  ai_prefix: "AI",
  system_content:'You are a helpful assistant that uses context to answer questions.',
  chatlog: [],
  chunk_settings_method: "Manual",
  chunk_settings_size: 250,
  chunk_settings_overlap: 0,
  chunk_settings_separator: " ",
  uuid: uuidv4(),
  info_mod_seen: false,
  logged_in_user: null,
  model_list: [],
  selected_model: "QABot(default)"
};

const reducerFn = (state = settings, action) => {
  //Settings Page
  if (action.type === "setOpenAiApiSettings") {
    return { ...state, openaikey: action.key };
  }
  if (action.type === "setPineconeApiSettings") {
    return { ...state, pineconekey: action.key };
  }
  if (action.type === "setCohereApiSettings") {
    return { ...state, coherekey: action.key };
  }
  //Data Input Page
  if (action.type === "setDataStoreProviderSettings") {
    return { ...state, store_provider: action.store };
  }
  if (action.type === "setDataStoreInputSettings") {
    return { ...state, store_input_type: action.store };
  }
  if (action.type === "setDataStoreIndexNameSettings") {
    return { ...state, store_index_name: action.store };
  }
  if (action.type === "setDataStoreNameSpaceSettings") {
    return { ...state, store_namespace: action.store };
  }
  if (action.type === "setDataStoreEmbeddingsProviderSettings") {
    return { ...state, store_embeddings_provider: action.store };
  }
  if (action.type === "setDataStoreEmbeddingsSettings") {
    return { ...state, store_embeddings: action.store };
  }
  if (action.type === "setDataStoreData") {
    return { ...state, store_data: action.store };
  }

  //Data Input Page - Chunk Settings
  if (action.type === "setChunkSettings.method") {
    return { ...state, chunk_settings_method: action.store };
  }
  if (action.type === "setChunkSettings.size") {
    return { ...state, chunk_settings_size: action.store };
  }
  if (action.type === "setChunkSettings.overlap") {
    return { ...state, chunk_settings_overlap: action.store };
  }
  if (action.type === "setChunkSettings.separator") {
    return { ...state, chunk_settings_separator: action.store };
  }

  //Chat Page
  if (action.type === "setGeneratorProviderSettings") {
    return { ...state, generator_provider: action.setting };
  }
  if (action.type === "setGeneratorEngineSettings") {
    return { ...state, generator_engine: action.setting };
  }
  if (action.type === "setGeneratorTemperatureSettings") {
    return { ...state, generator_temperature: action.setting };
  }
  if (action.type === "setGeneratorMaxTokenSettings") {
    return { ...state, generator_maxtoken: action.setting };
  }
  if (action.type === "setChatLog") {
    return { ...state, chatlog: action.chatlog };
  }

  //Edit Prompt
  if (action.type === "setPromptTemplate") {
    return { ...state, prompt_template: action.store };
  }
  if (action.type === "setPromptVariables") {
    return { ...state, prompt_variables: action.store };
  }
  if (action.type == "setHumanPrefix") {
    return { ...state, human_prefix: action.store };
  }
  if (action.type == "setAIPrefix") {
    return { ...state, ai_prefix: action.store };
  }
  if(action.type == "setSystemContent") {
    return { ...state, system_content: action.store };
  }

  //Info Modal
  if (action.type === "setInfoModSeen") {
    return { ...state, info_mod_seen: action.store };
  }

  //firebase login
  if (action.type === "setLogin") {
    return { ...state, logged_in_user: action.store };
  }

  //My Models
  if (action.type === "setModels") {
    return { ...state, model_list: action.store };
  }
  if (action.type === "setModel") {
    return { ...state, selected_model: action.store };
  }

  return state;
};
const store = createStore(reducerFn);
export default store;
