import { Button, Flex, Heading, Divider, Textarea } from "@chakra-ui/react";
import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { JsonBuild, fetchWithTimeout } from "../../utils/SettingsUtil";

const TextInput = () => {
  const dispatch = useDispatch();
  const data_to_store = useSelector((state) => state.store_data);

  function handleStore(e) {
    dispatch({ type: "setDataStoreData", store: e.target.value });
  }

  return (
    <Textarea
      marginTop="2"
      size="sm"
      borderRadius={8}
      h="90%"
      placeholder="Input your data here..."
      id="data-text-input"
      value={data_to_store}
      onChange={handleStore}
    />
  );
};

export default TextInput;
