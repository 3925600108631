import {
  Button,
  Flex,
  Input,
  FormControl,
  FormLabel,
  Heading,
  Divider,
  Textarea,
} from "@chakra-ui/react";
import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { JsonBuild, fetchWithTimeout } from "../../utils/SettingsUtil";

const YoutubeInput = () => {
  const dispatch = useDispatch();
  const data_to_store = useSelector((state) => state.store_data);

  function handleStore(e) {
    dispatch({ type: "setDataStoreData", store: e.target.value });
  }

  return (
    <FormControl>
      <FormLabel fontSize="14px">Youtube URL</FormLabel>
      <Input
        size="sm"
        marginBottom="1"
        borderRadius="8"
        maxW="80%"
        placeholder="https://www.youtube.com/..."
        id="data-text-input"
        value={data_to_store}
        onChange={handleStore}
      />
    </FormControl>
  );
};

export default YoutubeInput;
