import React from "react";
import { Select } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";

const EngineOptions = ({ provider }) => {
  const generator_engine = useSelector((state) => state.generator_engine);
  const dispatch = useDispatch();
  const handleSettingsChange = (e) => {
    dispatch({ type: "setGeneratorEngineSettings", setting: e.target.value });
  };
  const openai_choices = [
    "text-davinci-003",
    "text-curie-001",
    "text-babbage-001",
    "text-ada-001",
  ];
  const cohere_choices = [
    "large",
    "medium",
    "xlarge",
    "command-xlarge-20221108",
  ];
  const chatgpt_choices = [
    'gpt-3.5-turbo'
  ];

  return (
    <Select
      placeholder="Select Engine"
      size="sm"
      borderRadius="10"
      marginBottom="1"
      onChange={(e) => {
        handleSettingsChange(e);
      }}
      value={generator_engine}
    >
      {provider === "OpenAI" &&
        openai_choices.map((option, index) => (
          <option key={index}>{option}</option>
        ))}
      {provider === "Cohere" &&
        cohere_choices.map((option, index) => (
          <option key={index}>{option}</option>
        ))}
      {provider === "OpenAI_ChatGPT" &&
        chatgpt_choices.map((option, index) => (
          <option key={index}>{option}</option>
      ))}
    </Select>
  );
};

export default EngineOptions;
