import {
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  FormControl,
  FormLabel,
  Input,
  Text,
  useDisclosure,
  ButtonGroup,
  PopoverCloseButton,
  HStack,
} from "@chakra-ui/react";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchWithTimeout } from "../../utils/SettingsUtil";
import { JsonBuild } from "../../utils/SettingsUtil";

const NewModelPopover = (props) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  //redux
  const openaiKey = useSelector((state) => state.openaikey);
  const pineconeKey = useSelector((state) => state.pineconekey);
  const cohereKey = useSelector((state) => state.coherekey);
  const storeProvider = useSelector((state) => state.store_provider);
  const storeIndexName = useSelector((state) => state.store_index_name);
  const storeNameSpace = useSelector((state) => state.store_namespace);
  const storeEmbeddingsProvider = useSelector(
    (state) => state.store_embeddings_provider
  );
  const storeEmbeddings = useSelector((state) => state.store_embeddings);
  const generatorEngine = useSelector((state) => state.generator_engine);
  const generatorProvider = useSelector((state) => state.generator_provider);
  const generatorTemperature = useSelector(
    (state) => state.generator_temperature
  );
  const generatorMaxtoken = useSelector((state) => state.generator_maxtoken);
  const promptTemplate = useSelector((state) => state.prompt_template);
  const inputVariables = useSelector((state) => state.prompt_variables);
  const system_message = useSelector((state) => state.system_content);
  const uuid = useSelector((state) => state.uuid);
  const logged_in_user = useSelector((state) => state.logged_in_user);
  const chunk_settings_method = useSelector(
    (state) => state.chunk_settings_method
  );
  const chunk_settings_overlap = useSelector(
    (state) => state.chunk_settings_overlap
  );
  const chunk_settings_size = useSelector((state) => state.chunk_settings_size);
  const chunk_settings_separator = useSelector(
    (state) => state.chunk_settings_separator
  );

  //call api fetch here?
  const modelList = useSelector((state) => state.model_list);

  const dispatch = useDispatch();

  async function handleNewModel() {
    const new_model_name = document.getElementById("model_name").value;
    if (new_model_name === "") {
      alert("Please input name for new model");
      return false;
    }

    //check if model id is already used
    const dictExists = modelList.find(
      (dict) => dict.user_config.model_id === new_model_name
    );
    if (dictExists) {
      alert("The model name is already being used!");
      return false;
    }

    const json_string = JsonBuild(
      {
        generator_provider: generatorProvider,
        generator_temperature: generatorTemperature,
        generator_max_tokens: generatorMaxtoken,
        generator_engine_name: generatorEngine,
        datastore_provider: storeProvider,
        datastore_index_name: storeIndexName,
        datastore_name_space: storeNameSpace,
        embeddings_provider: storeEmbeddingsProvider,
        embeddings_engine_name: storeEmbeddings,
        input_variables: inputVariables,
        prompt_template: promptTemplate,
        system_message: system_message,
        openai_api_key: openaiKey,
        pinecone_api_key: pineconeKey,
        cohere_api_key: cohereKey,
        text_splitter_type: chunk_settings_method,
        text_splitter_separator: chunk_settings_separator,
        text_splitter_size: chunk_settings_size,
        text_splitter_overlap: chunk_settings_overlap,
        logged_in_user: logged_in_user,
        new_model_name: new_model_name,
      },
      "save_model"
    );

    //local - '/userdata/save_model_detail'
    //hosted process.env.REACT_APP_USER_DATA_SAVE_MODEL_ROUTE
    var res;
    try {
      res = await fetchWithTimeout(
        process.env.REACT_APP_USER_DATA_SAVE_MODEL_ROUTE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: process.env.REACT_APP_JWT_TOKEN,
          },
          body: JSON.stringify(json_string),
        }
      );
    } catch (error) {
      //catch timeout
      if (error.name === "AbortError") {
        alert("Timeout: Request took longer than 30 seconds to respond");
        return false;
      }
      return false;
    }
    //update modellist to have newly added model
    props.refreshModelList();
    document.getElementById("model_name").value = "";
  }

  return (
    <Popover placement="right" onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button size="sm" width="100%" variant="outline" colorScheme="pink">
          Save New Model +
        </Button>
      </PopoverTrigger>
      <PopoverContent p="5">
        <PopoverCloseButton />
        <FormControl>
          <FormLabel fontSize={"sm"}>
            Save current configuration as new model?
          </FormLabel>
          <HStack width={"100%"} spacing="2">
            <Input
              size="sm"
              placeholder="Model Name"
              borderRadius={"8"}
              flex={1}
              id="model_name"
            ></Input>
            <Button size="sm" colorScheme="pink" onClick={handleNewModel}>
              Save
            </Button>
            <div></div>
          </HStack>
        </FormControl>
      </PopoverContent>
    </Popover>
  );
};

export default NewModelPopover;
