import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";

const InfoModal = ({ title, description }) => {
  const dispatch = useDispatch();
  //bring all settings from redux
  const info_mod_seen = useSelector((state) => state.info_mod_seen);

  useEffect(() => {
    if (info_mod_seen === false) {
      onOpen();
      dispatch({ type: "setInfoModSeen", store: true });
    }
  }, []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = React.useRef(null);

  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader alignItems={"center"}>
            <Text align={"center"}>{title}</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody size="sm">{description}</ModalBody>
          <ModalFooter>
            <Button size="sm" colorScheme={"pink"} onClick={onClose}>
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InfoModal;
