import { Heading, Box, Text, Link } from "@chakra-ui/react";
import React from "react";
import {
  chatDocs,
  storeDataDocs,
  modelDocs,
  editPromptDocs,
  settingsDocs,
} from "../../utils/Messages";

const PlaygroundInstructions = () => {
  return (
    <>
      <Heading as="h4" size="md" margin="4">
        Storing Data
      </Heading>
      <Box margin="4" paddingLeft={"3"}>
        {storeDataDocs}
      </Box>
      <Heading as="h4" size="md" margin="4">
        Using the Chat
      </Heading>
      <Box margin="4" paddingLeft={"3"}>
        {chatDocs}
      </Box>
      <Heading as="h4" size="md" margin="4">
        Saving/Loading Models
      </Heading>
      <Box margin="4" paddingLeft={"3"}>
        {modelDocs}
      </Box>
      <Heading as="h4" size="md" margin="4">
        Editing Prompts
      </Heading>
      <Box margin="4" paddingLeft={"3"}>
        {editPromptDocs}
      </Box>
      <Heading as="h4" size="md" margin="4">
        Settings
      </Heading>
      <Box margin="4" paddingLeft={"3"}>
        {settingsDocs}
      </Box>
      <Box margin="4" paddingLeft={"3"}>
        <Link
          size="md"
          href="https://docs.google.com/document/d/1_alqXDJ6t4PMynD42uHA_0KvpgNzckrRr7gl_gY3W34/edit?usp=sharing"
          isExternal
          color={"blue"}
        >
          Privacy Policy
        </Link>
      </Box>
      <Box margin="4" paddingLeft={"3"}>
        <Link
          size="md"
          href="https://docs.google.com/document/d/1Vo-CuJp6jvwcUlvOl4jgaBQBH8bhRpD5h22QxYy10-M/edit?usp=sharing"
          isExternal
          color={"blue"}
        >
          Terms of Use
        </Link>
      </Box>
    </>
  );
};

export default PlaygroundInstructions;
