import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  Avatar,
  Button,
  Tooltip,
  Text
} from "@chakra-ui/react";
import React from "react";
import { RxAvatar } from "react-icons/rx";
import LoginButton from "../auth0/LoginButton";
import LogoutButton from "../auth0/LogoutButton";
import { useSelector, useDispatch } from "react-redux";

function ProfileMenu() {
  const logged_in_user = useSelector((state) => state.logged_in_user);

  return (
    <Menu>
      <Tooltip label="Account">
        <MenuButton
          as={Avatar}
          aria-label="Profile"
          size="sm"
          _hover={{ cursor: "pointer" }}
        ></MenuButton>
      </Tooltip>
      <MenuList>
        {logged_in_user && <Text style={{ textAlign: "center" }}>{logged_in_user.email}</Text>}
        {logged_in_user ? (
          <MenuItem>
            <LogoutButton />
          </MenuItem>
        ) : (
          <MenuItem>
            <LoginButton />
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}

export default ProfileMenu;
