import {
  FormControl,
  Heading,
  Select,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { React, useEffect, useState } from "react";
import CharacterSplitter from "./splitters/CharacterSplitter";
import Tiktoken from "./splitters/Tiktoken";
import { useSelector, useDispatch } from "react-redux";

const ChunkSettings = () => {
  const chunkMethod = useSelector((state) => state.chunk_settings_method);
  const store_input_type = useSelector((state) => state.store_input_type);
  const dispatch = useDispatch();

  const handleSettingsChange = (e) => {
    dispatch({ type: "setChunkSettings.method", store: e.target.value });
  };

  const renderOption = (method) => {
    if (method === "Manual") {
        return <Text fontSize='sm'> <strong>Disclaimer: </strong>For manual chunking please consider splitting your data into small sensible chunks. We recommend using TikToken or Character splitter for long bodies of texts.</Text>;
    } else if (method === "TikTokenSplitter") {
      return <Tiktoken />;
    } else {
      return <CharacterSplitter />;
    }
  };

  return (
    <FormControl p="6">
      <Heading as="h4" size="sm" marginBottom="2">
        Chunk Splitting
      </Heading>
      <FormLabel fontSize="14px">Splitter</FormLabel>
      <Select
        size="sm"
        borderRadius="10"
        marginBottom="1"
        id="chunk-settings"
        onChange={(e) => handleSettingsChange(e)}
        value={chunkMethod}
      >
        <option value="Manual">Manual</option>
        <option value="TikTokenSplitter">TikToken Splitter</option>
        <option value="CharacterSplitter">Character Splitter</option>
      </Select>
      {renderOption(chunkMethod)}
    </FormControl>
  );
};

export default ChunkSettings;
