import {
  Box,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";

function MesssageDiv({ userSender, message }) {
  return (
    <Box
      as={userSender ? "div" : "button"}
      bg={userSender ? "gray.100" : "pink.600"}
      color={userSender ? "black" : "white"}
      px={4}
      py={3}
      maxW={80}
      borderTopLeftRadius={32}
      borderTopRightRadius={32}
      borderBottomLeftRadius={userSender ? 32 : 0}
      borderBottomRightRadius={userSender ? 0 : 32}
      fontSize="14"
      textAlign={userSender ? "left" : "left"}
    >
      {message.message}
    </Box>
  );
}

function BubbleType({ userSender, message }) {
  if (userSender) {
    return <MesssageDiv userSender={userSender} message={message} />;
  } else {
    return (
      <Popover placement={"right"}>
        <PopoverTrigger>
          <Box>
            <MesssageDiv userSender={userSender} message={message} />
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontSize={"14"} border="0" fontWeight={"semibold"}>
            Message Details
          </PopoverHeader>
          <PopoverBody fontSize={"14"}>
            Matched Context: {message.context}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
}

const ChatBubble = ({ message }) => {
  const userSender = message.sender === "User";
  const alignment = userSender ? "flex-end" : "flex-start";

  return (
    <VStack mt={5} alignItems={alignment} alignSelf={alignment} mb={2}>
      <BubbleType userSender={userSender} message={message} />
    </VStack>
  );
};

export default ChatBubble;
