import { React, useState, useEffect, useRef } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Input,
  Textarea,
  FormControl,
  Heading,
  IconButton,
  Tooltip,
  HStack,
  FormLabel,
} from "@chakra-ui/react";
import PromptVariable from "./PromptVariable";
import { useSelector, useDispatch } from "react-redux";
import { EditIcon } from "@chakra-ui/icons";
import SystemMessage from "./SystemMessage";

const PromptEditor = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const promptTemplate = useSelector((state) => state.prompt_template);
  const promptVariables = useSelector((state) => state.prompt_variables);
  const humanPrefix = useSelector((state) => state.human_prefix);
  const aiPrefix = useSelector((state) => state.ai_prefix);
  const generatorProvider = useSelector((state) => state.generator_provider);

  const handleChange = (e) => {
    dispatch({ type: "setPromptTemplate", store: e.target.value });

    var copyArray = promptVariables;
    const extractedValues = [];
    const regex = /{([^{}]+)}/g;
    let match;

    while ((match = regex.exec(e.target.value)) !== null) {
      extractedValues.push(match[1]);
    }

    //check for removals
    const arrLength = copyArray.length;
    for (var i = 0; i < copyArray.length; i++) {
      if (!extractedValues.includes(copyArray[i].name)) {
        copyArray.splice(i, 1);
        i--;
      }
    }

    const nameArray = copyArray.map((x) => x.name);
    //check for additions
    for (const element of extractedValues) {
      if (!nameArray.includes(element)) {
        copyArray.push({ type: "Context", name: element });
      }
    }

    dispatch({ type: "setPromptVariables", store: copyArray });
  };

  const updatePrefix = (e, prefix_type) => {
    if (prefix_type === "human") {
      dispatch({ type: "setHumanPrefix", store: e.target.value });
    } else if (prefix_type === "ai") {
      dispatch({ type: "setAIPrefix", store: e.target.value });
    }
  };

  return (
    <>
      <Tooltip label="Edit Prompt">
        <IconButton ref={btnRef} size="sm" onClick={onOpen} icon={<EditIcon />}>
          Edit Prompt
        </IconButton>
      </Tooltip>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader size="md">Edit Prompt</DrawerHeader>
          <DrawerBody>
            {generatorProvider === "OpenAI_ChatGPT" && <SystemMessage />}
            {generatorProvider === "OpenAI_ChatGPT" && (
              <Tooltip label="Prompt sent on every message to ChatGPT">
                <Heading as="h4" size="sm" marginBottom="2" marginTop="3">
                  Message Prompt
                </Heading>
              </Tooltip>
            )}
            <Textarea
              size="sm"
              borderRadius={8}
              h="60%"
              placeholder="Input your prompt here..."
              value={promptTemplate}
              onChange={handleChange}
            />
            <Heading as="h4" size="sm" marginBottom="2" marginTop="3">
              Prompt Variables
            </Heading>
            <HStack spacing={"4"}>
              <div>
                {generatorProvider != "OpenAI_ChatGPT" && (
                  <FormControl px="3" paddingTop="1">
                    <FormLabel fontSize="14px">Human Name</FormLabel>
                    <Input
                      size="sm"
                      placeholder="human_prefix"
                      borderRadius={"8"}
                      value={humanPrefix}
                      onChange={(e) => updatePrefix(e, "human")}
                    ></Input>
                  </FormControl>
                )}
              </div>
              <div>
                {generatorProvider != "OpenAI_ChatGPT" && (
                  <FormControl px="3" paddingTop="1">
                    <FormLabel fontSize="14px">AI Name</FormLabel>
                    <Input
                      size="sm"
                      placeholder="ai_prefix"
                      borderRadius={"8"}
                      value={aiPrefix}
                      onChange={(e) => updatePrefix(e, "ai")}
                    ></Input>
                  </FormControl>
                )}
              </div>
            </HStack>
            { promptVariables.map((variable, index) => (
              <PromptVariable key={index} variable={variable} />
            ))}
          </DrawerBody>
          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default PromptEditor;
