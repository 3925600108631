import { SimpleGrid,
    Box, 
    Heading,
    Divider, 
    GridItem,
    HStack,
    Button
    } from '@chakra-ui/react'
import GeneratorSettings from '../components/GeneratorSettings'
import DataRetrieveSettings from '../components/DataRetrieveSettings'
import ChatWindow from '../components/ChatWindow'
import InfoModal from '../components/InfoModal'
import { welcomeDescription, welcomeTitle } from '../utils/Messages'
import LoginButton from '../components/auth0/LoginButton'
import ModelList from '../components/models/ModelList'


const Chat = () => {
  return (
    <SimpleGrid columns={2} templateColumns="repeat(3, 1fr)">
        <InfoModal title={welcomeTitle} description={welcomeDescription} key='info-placeholder-key'/>
        <Box height='100vh' bg="white" overflowY={'auto'}>
        <HStack spacing = "4">
        <Heading as='h4' size='md' p ="4" flex = {1}>
            Chat Settings
        </Heading>
        <ModelList />
        <div></div>
        </HStack>
        <Divider orientation='horizontal' />
        <GeneratorSettings />
        <DataRetrieveSettings />
      </Box>
      <GridItem colSpan={2}>
        <ChatWindow />
      </GridItem>
    </SimpleGrid>
  );
};

export default Chat;
