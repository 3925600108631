import { useSelector, useDispatch } from "react-redux";
import React from "react";
import store from "../store";
import CryptoJS from "crypto-js";

const algorithm = process.env.REACT_APP_ENCRYPT_ALGORITHM;
const secretKey = process.env.REACT_APP_ENCRYPT_KEY;
const iv = CryptoJS.lib.WordArray.random(16);

function encrypt(text) {
  const cipher = CryptoJS.AES.encrypt(text, secretKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return {
    iv: iv.toString(),
    encryptedData: cipher.toString(),
  };
}

function decrypt(hash) {
  const decrypted = CryptoJS.AES.decrypt(hash.encryptedData, secretKey, {
    iv: CryptoJS.enc.Hex.parse(hash.iv),
    mode: CryptoJS.mode.CBC,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

//settings = reduxsettings{}
//type = type of api call the json is for

function JsonBuild(settings, type) {
  const api_json = {};

  if (type === "data_chat") {
    //for data_chat

    //generator provider - llm config
    //OpenAI
    if (settings.generator_provider === "OpenAI") {
      api_json.llm_config = {
        type: settings.generator_provider,
        temperature: settings.generator_temperature,
        max_tokens: settings.generator_max_tokens,
      };
      if (settings.generator_engine_name === "") {
        alert("Generator Engine Not Specified");
        return false;
      }
      api_json.llm_config.engine_name = settings.generator_engine_name;
      if (settings.openai_api_key === "") {
        alert("OpenAI API Key Not Specified");
        return false;
      }
      api_json.llm_config.api_key = settings.openai_api_key;
    }
    //Cohere
    else if (settings.generator_provider === "Cohere") {
      api_json.llm_config = {
        type: settings.generator_provider,
        temperature: settings.generator_temperature,
        max_tokens: settings.generator_max_tokens,
      };
      if (settings.generator_engine_name === "") {
        alert("Generator Engine Not Specified");
        return false;
      }
      api_json.llm_config.engine_name = settings.generator_engine_name;
      if (settings.cohere_api_key === "") {
        alert("Cohere API Key Not Specified");
        return false;
      }
      api_json.llm_config.api_key = settings.cohere_api_key;
    } 
    //CHAT GPT only
    else if (settings.generator_provider === "OpenAI_ChatGPT") {
        api_json.llm_config = {
            type: settings.generator_provider,
            temperature: settings.generator_temperature,
            max_tokens: settings.generator_max_tokens,
            prefix:[]
          };
        //build prefix here
        if (settings.system_content === "") {
            alert("System Message cannot be empty when using ChatGPT");
            return false;
        }
        const prefix_build = [{'role':'system', 'content':settings.system_content}, ...settings.prefixLog]
        api_json.llm_config.prefix = prefix_build
        if (settings.generator_engine_name === "") {
            alert("Generator Engine Not Specified");
            return false;
        }
        //ignore engine name?
        //api_json.llm_config.engine_name = settings.generator_engine_name;
        if (settings.openai_api_key === "") {
            alert("OpenAI API Key Not Specified");
            return false;
        }
        api_json.llm_config.api_key = settings.openai_api_key;
    }
    
    else {
      alert("Generator Provider Not Specified");
      return false;
    }

    //Storage provider -  datastore_config
    //Pinecone
    if (settings.datastore_provider === "Pinecone") {
      api_json.datastore_config = { type: settings.datastore_provider };
      if (settings.pinecone_api_key === "") {
        alert("Pinecone API Key Not Specified");
        return false;
      }
      api_json.datastore_config.api_key = settings.pinecone_api_key;
      if (settings.datastore_index_name === "") {
        alert("Datastore Index Name Not Specified");
        return false;
      }
      if (settings.datastore_name_space !== "") {
        api_json.datastore_config.namespace = settings.datastore_name_space;
      }
      api_json.datastore_config.index_name = settings.datastore_index_name;
    } else if (settings.datastore_provider === "Faiss") {
      api_json.datastore_config = {
        type: settings.datastore_provider,
        uuid: settings.uuid,
      };
    } else {
      alert("Datastore Provider Not Specified");
      return false;
    }

    //embeddings provider -  embeddings_config
    //OpenAI
    if (settings.embeddings_provider === "OpenAI") {
      api_json.embeddings_config = { type: settings.embeddings_provider };
      if (settings.embeddings_engine_name === "") {
        alert("Embedding Type Not Specified");
        return false;
      }
      api_json.embeddings_config.engine_name = settings.embeddings_engine_name;
      if (settings.openai_api_key === "") {
        alert("OpenAI API Key Not Specified");
        return false;
      }
      api_json.embeddings_config.api_key = settings.openai_api_key;
    }
    //Cohere
    else if (settings.embeddings_provider === "Cohere") {
      api_json.embeddings_config = { type: settings.embeddings_provider };
      if (settings.embeddings_engine_name === "") {
        alert("Embedding Type Not Specified");
        return false;
      }
      api_json.embeddings_config.engine_name = settings.embeddings_engine_name;
      if (settings.cohere_api_key === "") {
        alert("Cohere API Key Not Specified");
        return false;
      }
      api_json.embeddings_config.api_key = settings.cohere_api_key;
    } else {
      alert("Embeddings Provider Not Specified");
      return false;
    }

    //prompt_config
    const typeList = settings.input_variables.map((x) => x.type);
    if (!typeList.includes("Memory") && settings.generator_provider != "OpenAI_ChatGPT") {
      alert("Must use Memory type variable in prompt");
      return false;
    }
    if (!typeList.includes("Input")) {
      alert("Must use Input type variable in prompt");
      return false;
    }
    if (!typeList.includes("Context")) {
      alert("Must use Context type variable in prompt");
      return false;
    }
    api_json.prompt_config = {
      input_variables: settings.input_variables,
      prompt_template: settings.prompt_template,
      human_prefix: settings.human_prefix,
      ai_prefix: settings.ai_prefix,
    };

    // message
    api_json.message = settings.message;
    api_json.uuid = settings.uuid;
    // conv_history
    if(settings.generator_provider === "OpenAI_ChatGPT"){
        api_json.conv_history = []
    }
    else {
      api_json.conv_history = settings.conv_history;
    }
    
    return api_json;
  }

  if (type === "data_store") {
    //for data_store

    //Storage provider -  datastore_config
    //Pinecone
    if (settings.datastore_provider === "Pinecone") {
      api_json.datastore_config = { type: settings.datastore_provider };
      if (settings.pinecone_api_key === "") {
        alert("Pinecone API Key Not Specified");
        return false;
      }
      api_json.datastore_config.api_key = settings.pinecone_api_key;
      if (settings.datastore_index_name === "") {
        alert("Datastore Index Name Not Specified");
        return false;
      }
      api_json.datastore_config.index_name = settings.datastore_index_name;
      if (settings.datastore_name_space !== "") {
        api_json.datastore_config.namespace = settings.datastore_name_space;
      }
    }
    //Faiss
    else if (settings.datastore_provider === "Faiss") {
      api_json.datastore_config = {
        type: settings.datastore_provider,
        uuid: settings.uuid,
      };
    } else {
      alert("Datastore Provider Not Specified");
      return false;
    }

    //embeddings provider -  embeddings_config
    //OpenAI
    if (settings.embeddings_provider === "OpenAI") {
      api_json.embeddings_config = { type: settings.embeddings_provider };
      if (settings.embeddings_engine_name === "") {
        alert("Embedding Type Not Specified");
        return false;
      }
      api_json.embeddings_config.engine_name = settings.embeddings_engine_name;
      if (settings.openai_api_key === "") {
        alert("OpenAI API Key Not Specified");
        return false;
      }
      api_json.embeddings_config.api_key = settings.openai_api_key;
    }
    //Cohere
    else if (settings.embeddings_provider === "Cohere") {
      api_json.embeddings_config = { type: settings.embeddings_provider };
      if (settings.embeddings_engine_name === "") {
        alert("Embedding Type Not Specified");
        return false;
      }
      api_json.embeddings_config.engine_name = settings.embeddings_engine_name;
      if (settings.cohere_api_key === "") {
        alert("Cohere API Key Not Specified");
        return false;
      }
      api_json.embeddings_config.api_key = settings.cohere_api_key;
    } else {
      alert("Embeddings Provider Not Specified");
      return false;
    }

    //Chunking Method -  text_splitter_config:
    //Tiktoken
    api_json.text_splitter_config = { type: settings.text_splitter_type };
    if (settings.text_splitter_type === "TikTokenSplitter") {
      api_json.text_splitter_config.chunk_size = settings.text_splitter_size;
      api_json.text_splitter_config.chunk_overlap =
        settings.text_splitter_overlap;
    }
    //Character
    else if (settings.text_splitter_type === "CharacterSplitter") {
      if (settings.text_splitter_separator === "") {
        alert("Character Splitter Separator Not Specified");
        return false;
      }
      api_json.text_splitter_config.separator =
        settings.text_splitter_separator;
      api_json.text_splitter_config.chunk_size = settings.text_splitter_size;
      api_json.text_splitter_config.chunk_overlap =
        settings.text_splitter_overlap;
    }
    //Manual
    else {
      //do nothing
    }

    //Input Type
    //Youtube Url
    api_json.datareader_config = { type: settings.datastore_store_input };
    if (settings.datastore_store_input === "Youtube") {
      api_json.datareader_config.url = settings.texts;
    }
    //Text
    else {
      api_json.datareader_config.text = settings.texts;
    }
    api_json.session_id = settings.uuid;

    return api_json;
  }

  //saveModel Request
  if (type === "save_model") {
    //for save_model

    //data_store
    api_json.datastore_config = { type: settings.datastore_provider };
    api_json.datastore_config.api_key = "blank";
    api_json.datastore_config.index_name = settings.datastore_index_name;
    api_json.datastore_config.namespace = settings.datastore_name_space;
    //embeddings
    api_json.embeddings_config = { type: settings.embeddings_provider };
    api_json.embeddings_config.engine_name = settings.embeddings_engine_name;
    api_json.embeddings_config.api_key = "blank";
    //splitter
    api_json.text_splitter_config = { type: settings.text_splitter_type };
    api_json.text_splitter_config.chunk_size = settings.text_splitter_size;
    api_json.text_splitter_config.chunk_overlap =
      settings.text_splitter_overlap;
    //llm
    api_json.llm_config = {
      type: settings.generator_provider,
      temperature: settings.generator_temperature,
      max_tokens: settings.generator_max_tokens,
    };
    api_json.llm_config.engine_name = settings.generator_engine_name;
    api_json.llm_config.api_key = "blank";
    //user_config
    api_json.user_config = {
      user_id: settings.logged_in_user.uid,
      model_id: settings.new_model_name,
    };
    //prompt_config
    api_json.prompt_config = {
      input_variables: settings.input_variables,
      prompt_template: settings.prompt_template,
      human_prefix: settings.human_prefix,
      ai_prefix: settings.ai_prefix,
      system_message: settings.system_message
    };
    //api_keys - encode
    const e_openai = encrypt(settings.openai_api_key);
    const e_cohere = encrypt(settings.cohere_api_key);
    const e_pinecone = encrypt(settings.pinecone_api_key);
    api_json.api_config = {
      open_ai: e_openai,
      cohere: e_cohere,
      pinecone: e_pinecone,
    };
    //no encrpyt
    //api_json.api_config = {'open_ai':settings.pinecone_api_key, 'cohere':settings.pinecone_api_key, 'pinecone':settings.pinecone_api_key}

    return api_json;
  }

  alert("Unsupported Api Call");
  return false;
}

async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 30000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}

export { JsonBuild, fetchWithTimeout, encrypt, decrypt };
