import {
  FormControl,
  Slider,
  SliderThumb,
  SliderFilledTrack,
  SliderMark,
  FormLabel,
  SliderTrack,
} from "@chakra-ui/react";
import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

const Tiktoken = () => {
  const chunkSizeRedux = useSelector((state) => state.chunk_settings_size);
  const chunkOverlapRedux = useSelector(
    (state) => state.chunk_settings_overlap
  );
  const [chunkSize, setChunkSize] = useState(chunkSizeRedux);
  const [chunkOverlap, setChunkOverlap] = useState(chunkOverlapRedux);
  const dispatch = useDispatch();

  return (
    <FormControl>
      <FormLabel fontSize="14px" mb={5}>
        Chunk Size (Tokens)
      </FormLabel>
      <Slider
        id="size"
        aria-label="slider-ex-2"
        colorScheme="pink"
        max="4000"
        defaultValue={chunkSize}
        step="1"
        onChange={(val) => {
          setChunkSize(val);
          dispatch({ type: "setChunkSettings.size", store: val });
        }}
      >
        <SliderMark
          value={chunkSize}
          textAlign="center"
          bg="gray.200"
          borderRadius="8"
          color="black"
          mt="-8"
          ml="-5"
          w="12"
          fontSize="xs"
        >
          {chunkSize}
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      <FormLabel fontSize="14px" mb={5}>
        Chunk Overlap (Tokens)
      </FormLabel>
      <Slider
        id="overlap"
        aria-label="slider-ex-2"
        colorScheme="pink"
        max="2000"
        defaultValue={chunkOverlap}
        step="1"
        onChange={(val) => {
          setChunkOverlap(val);
          dispatch({ type: "setChunkSettings.overlap", store: val });
        }}
      >
        <SliderMark
          value={chunkOverlap}
          textAlign="center"
          bg="gray.200"
          borderRadius="8"
          color="black"
          mt="-8"
          ml="-5"
          w="12"
          fontSize="xs"
        >
          {chunkOverlap}
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </FormControl>
  );
};

export default Tiktoken;
