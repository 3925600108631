import {
  Image,
  List,
  ListItem,
  OrderedList,
  Text,
  VStack,
} from "@chakra-ui/react";

export const welcomeDescription = [
  "Here you can store your data in various datastores, retrieve it from the same, and then chat with it using Generative Models provided by your preferred provider! For more information on each feature please refer to the",
  <a href="/docs" style={{ color: "blue" }}>
    {" "}
    Docs Page.
  </a>,
  <br></br>,
  <br></br>,
  <strong>To Get Started:</strong>,
  <br></br>,
  <li>
    <strong>Store Data: </strong>Head over to the “Store Data” tab, select a
    provider of your choice and enter your data in the text field. Use an
    embedding mechanism and a chunking mechanism of your choice to break the
    data into sensible and smaller chunks if needed and then embed them. (Make
    sure your API keys are configured in Settings)
  </li>,
  <li>
    <strong>Chat: </strong>After storing your data, proceed to the "Chat" tab to
    engage in a conversation with it. Select a preferred provider and retrieve
    the data from the previously used datastore. Take it for a spin!
  </li>,
];
export const welcomeTitle = [
  "Welcome to the Data Playground ",
  <span>&#128075;</span>,
];

export const docsWelcomeTitle = [
  <strong>Welcome to the Data Playground! </strong>,
  <span>&#128075;</span>,
  <br></br>,
  "Here you can store your data in various datastores, retrieve it from the same, and then chat with it using Generative Models provided by your preferred provider.",
];

export const storeDataDocs = [
  <OrderedList>
    <ListItem>
      Navigate to the <strong>Store Data Tab</strong>
    </ListItem>
    <ListItem>
      Input all the fields required by your vector store provider selection.{" "}
      <em>
        For detailed guides on each vectorstore please visit their website.
      </em>
    </ListItem>
    <ListItem>
      Choose the embeddings type that you will store the data as. (This must
      remain consistent throughout the model/storage, you cannot store data with
      different embeddings types for the same model/storage)
    </ListItem>
    <ListItem>
      Choose a chunk splitting method for your data, if you choose the manual
      method, you may get a warning if you are trying to store data that is too
      large for one vector.
    </ListItem>
    <ListItem>Choose the data source of your data input.</ListItem>
    <ListItem>
      Input the required fields for the data source you have chosen. (ex:
      Youtube Url) then press ‘Store Data’ to store that data into your vector
      store.
    </ListItem>
    <ListItem>
      You may put many vectors into a Pinecone index but Faiss vector stores
      will have a limited amount and also can only house data for one model at a
      time.
    </ListItem>
  </OrderedList>,
];

export const chatDocs = [
  <OrderedList>
    <ListItem>
      Go to the <strong>Chat Page.</strong>
    </ListItem>
    <ListItem>Select the engine of your provider.</ListItem>
    <ListItem>
      Set the temperature and max token of the response it will generate.
    </ListItem>
    <ListItem>Select your vector store provider: ex: Pinecone</ListItem>
    <ListItem>
      Input the information of the data source that you have stored before.
    </ListItem>
    <ListItem>
      Make sure the embedding type is selected to be the same as when you stored
      the data into the vector store.
    </ListItem>
    <ListItem>
      For Advanced settings, you may change the prompt and its variables on the
      top right 'Edit Prompt' button
      <VStack>
        <Image boxSize="xs" src="edit-prompt.gif" width="375px"></Image>
      </VStack>
    </ListItem>
    <ListItem>
      After all the previous steps are set, Type in your message at the bottom
      of the Chat Window to get a response from your model.
    </ListItem>
    <ListItem>
      You may clear the chat using the ‘Clear Chat’ button on the top right as
      well.
    </ListItem>
  </OrderedList>,
];

export const modelDocs = [
  <VStack marginTop="3" marginBottom={"3"}>
    <Image boxSize="xs" src="models.gif" width={"450px"} />
  </VStack>,
  <OrderedList>
    <ListItem>
      Log in through Google by clicking on the 'Account' button on the top right
    </ListItem>
    <ListItem>Click 'My Models' on the Chat or Store Data page</ListItem>
    <ListItem>
      To save current inputs, either:
      <OrderedList>
        <ListItem>
          Click 'Save New Model +' and input a name to be saved as
        </ListItem>
        <ListItem>Press 'Update Model' of an existing model</ListItem>
      </OrderedList>
    </ListItem>
    <ListItem>
      To load a saved model, simply click on the model you would like to load
    </ListItem>
    <ListItem>
      The name of the selected model will appear on the top side of the chat
      window
    </ListItem>
  </OrderedList>,
];

export const editPromptDocs = [
  <VStack marginTop="3" marginBottom={"3"}>
    <Image src="edit-prompt-variables.gif" boxSize={"sm"}></Image>
  </VStack>,
  <OrderedList>
    <ListItem>
      For advanced settings, you may change the prompt and its variables on the
      'Edit Prompt' button on the top right.
    </ListItem>
    <ListItem>
      Define a variable in the prompt by putting it between curly braces. ex:{" "}
      {"{test_variable}"}
    </ListItem>
    <ListItem>
      Once you define a variable, you will be able to choose between the options
      of variable types.
      <OrderedList>
        <ListItem>
          Context: This is the most similar data retrieved from your datastore.
          To see example placement click edit prompt. This is a required
          variable for all types of data prompts.
        </ListItem>
        <ListItem>
          Memory: This corresponds to your conversation history. Usually put
          this above the current message.
        </ListItem>
        <ListItem>
          Human Input: This corresponds to the message you type in the chatbox.
        </ListItem>
      </OrderedList>
    </ListItem>
    <ListItem>
      When using the OpenAI_ChatGPT provider, you will be able to additionally
      define a ‘System message’ that will be injected into the start of your
      request.
    </ListItem>
  </OrderedList>,
];

export const settingsDocs = [
  <OrderedList>
    <ListItem>
      To obtain your API keys, please navigate to your provider's website and
      follow the necessary steps outlined on their platform. This will enable
      you to access and use the provider's services within the Data Playground.
    </ListItem>
    <ListItem>
      To begin using the data playground, kindly navigate to the settings page
      and input the respective API keys for the providers you intend to use.
      This will allow the playground to securely connect to your provider's
      services and enable you to store and retrieve data, as well as generate
      responses from chat engines. Ensure that you input the correct API keys
      for each provider to prevent any authentication errors.
    </ListItem>
    <br />
    <Text>
      <strong>Note:</strong> The API keys used by the data playground are
      encrypted and never stored in plain text. Instead, only the hash of the
      keys is stored for security purposes. This ensures that the keys remain
      protected. At Mitras AI, we take data security seriously and strive to
      maintain the highest standards of protection for our users' sensitive
      information.
    </Text>
  </OrderedList>,
];
