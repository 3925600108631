import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Heading,
  Button,
  Box,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import EmbeddingsOptions from "../embeddings/EmbeddingsOptions";

const PineconeSettings = () => {
  const dispatch = useDispatch();
  //set settings states as user inputs right away
  const store_index_name = useSelector((state) => state.store_index_name);
  const store_namespace = useSelector((state) => state.store_namespace);
  const store_embeddings_provider = useSelector(
    (state) => state.store_embeddings_provider
  );

  //Handle any settings input changes
  const handleSettingsInput = (e) => {
    if (e.target.id === "data-store-index") {
      dispatch({
        type: "setDataStoreIndexNameSettings",
        store: document.getElementById("data-store-index").value,
      });
    }
    if (e.target.id === "data-store-namespace") {
      dispatch({
        type: "setDataStoreNameSpaceSettings",
        store: document.getElementById("data-store-namespace").value,
      });
    }
    if (e.target.id === "data-store-embeddings-provider") {
      dispatch({
        type: "setDataStoreEmbeddingsProviderSettings",
        store: document.getElementById("data-store-embeddings-provider").value,
      });
    }
  };

  return (
    <FormControl>
      <FormControl isRequired>
        <FormLabel fontSize="14px">Index Name</FormLabel>
        <Input
          placeholder="Input index name here..."
          size="sm"
          marginBottom="1"
          borderRadius="8"
          id="data-store-index"
          value={store_index_name}
          onChange={handleSettingsInput}
        ></Input>
      </FormControl>
      <FormLabel fontSize="14px">Namespace</FormLabel>
      <Input
        placeholder="Input Namespace here..."
        size="sm"
        marginBottom="1"
        borderRadius="8"
        id="data-store-namespace"
        value={store_namespace}
        onChange={handleSettingsInput}
      ></Input>
      <FormControl isRequired>
        <FormLabel fontSize="14px">Embeddings</FormLabel>
        <Select
          placeholder="Select Embedding Provider"
          size="sm"
          borderRadius="10"
          marginBottom="1"
          id="data-store-embeddings-provider"
          value={store_embeddings_provider}
          onChange={handleSettingsInput}
        >
          <option value="OpenAI">OpenAI</option>
          <option value="Cohere">Cohere</option>
        </Select>
        <EmbeddingsOptions provider={store_embeddings_provider} />
      </FormControl>
    </FormControl>
  );
};

export default PineconeSettings;
