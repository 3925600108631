import {
  FormControl,
  Select,
  FormLabel,
  Heading,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Box,
  Button,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import EngineOptions from "./generators/EngineOptions";

const GeneratorSettings = () => {
  const dispatch = useDispatch();
  const generator_provider = useSelector((state) => state.generator_provider);
  const generator_temp = useSelector((state) => state.generator_temperature);
  const generator_maxtoken = useSelector((state) => state.generator_maxtoken);

  //Had to put dispatch call inline with html for Sliders as the id reading did not work on them
  const handleSettingsInput = (e) => {
    if (e.target.id === "generator-provider") {
      dispatch({
        type: "setGeneratorProviderSettings",
        setting: document.getElementById("generator-provider").value,
      });
      //ChatGpt - change all default values to not include Memory variable
      if(e.target.value === 'OpenAI_ChatGPT') {
        const default_prompt = "Context: {context}\n\n{message}"
        const default_input_var = [
          { type: "Context", name: "context" },
          { type: "Input", name: "message" },
        ]
        dispatch({ type: "setPromptTemplate", store: default_prompt })
        dispatch({ type: "setPromptVariables", store: default_input_var })
      }
      //Change back to default
      else {
        const default_prompt =  "You are a chatbot having a conversation with a human.\n\nGiven the following extracted parts of a long document and a question, create a final answer.\n\n{context}\n\n{chat_logs}\nHuman: {message}\nAI:"
        const default_input_var = [
          { type: "Context", name: "context" },
          { type: "Memory", name: "chat_logs" },
          { type: "Input", name: "message" },
        ]
        dispatch({ type: "setPromptTemplate", store: default_prompt })
        dispatch({ type: "setPromptVariables", store: default_input_var })
      }
    }
  };
  //updates slider visuals
  const [tempSliderValue, setTempSliderValue] = useState(
    generator_temp * 100.0
  );
  const [tokenSliderValue, setTokenSliderValue] = useState(generator_maxtoken);
  const labelStyles = {
    mt: "2",
    ml: "0",
    fontSize: "sm",
  };

  return (
    <FormControl p="6">
      <Heading as="h4" size="sm" marginBottom="2">
        Generator Settings
      </Heading>
      <FormControl isRequired>
        <FormLabel fontSize="14px">Providers</FormLabel>
        <Select
          placeholder="Select Provider"
          size="sm"
          borderRadius="10"
          marginBottom="1"
          id="generator-provider"
          onChange={handleSettingsInput}
          value={generator_provider}
        >
          <option>OpenAI</option>
          <option>Cohere</option>
          <option>OpenAI_ChatGPT</option>
        </Select>
      </FormControl>
      <FormControl isRequired>
        <FormLabel fontSize="14px">Engine</FormLabel>
        <EngineOptions provider={generator_provider} />
      </FormControl>
      <FormLabel fontSize="14px">Temperature</FormLabel>
      <Slider
        aria-label="slider-ex-2"
        colorScheme="pink"
        defaultValue={tempSliderValue}
        max="100"
        step="1"
        mb="4"
        id="generator-temperature"
        onChange={(val) => {
          setTempSliderValue(val);
          dispatch({
            type: "setGeneratorTemperatureSettings",
            setting: val / 100.0,
          });
        }}
      >
        <SliderMark value={0} {...labelStyles}>
          0.0
        </SliderMark>
        <SliderMark value={100} {...labelStyles} ml="-4">
          1.0
        </SliderMark>
        <SliderMark
          value={tempSliderValue}
          textAlign="center"
          bg="gray.200"
          borderRadius="8"
          color="black"
          mt="-8"
          ml="-5"
          w="12"
          fontSize="xs"
        >
          {tempSliderValue / 100}
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      <FormLabel fontSize="14px">Max Tokens</FormLabel>
      <Slider
        aria-label="slider-ex-2"
        colorScheme="pink"
        max="512"
        defaultValue={tokenSliderValue}
        step="1"
        onChange={(val) => {
          setTokenSliderValue(val);
          dispatch({ type: "setGeneratorMaxTokenSettings", setting: val });
        }}
      >
        <SliderMark
          value={tokenSliderValue}
          textAlign="center"
          bg="gray.200"
          borderRadius="8"
          color="black"
          mt="-8"
          ml="-5"
          w="12"
          fontSize="xs"
        >
          {tokenSliderValue}
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </FormControl>
  );
};

export default GeneratorSettings;
