import React from "react";
import { Select } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";

const EmbeddingsOptions = ({ provider }) => {
  const store_embeddings = useSelector((state) => state.store_embeddings);
  const dispatch = useDispatch();
  const handleSettingsChange = (e) => {
    dispatch({ type: "setDataStoreEmbeddingsSettings", store: e.target.value });
  };
  const cohere_choices = ["large", "small", "multilingual-22-12"];
  const openai_choices = ["text-embedding-ada-002"];

  return (
    <Select
      placeholder="Select Embedding Type"
      size="sm"
      borderRadius="10"
      marginBottom="1"
      onChange={(e) => {
        handleSettingsChange(e);
      }}
      value={store_embeddings}
    >
      {provider === "OpenAI" &&
        openai_choices.map((option, index) => (
          <option key={index}>{option}</option>
        ))}
      {provider === "Cohere" &&
        cohere_choices.map((option, index) => (
          <option key={index}>{option}</option>
        ))}
    </Select>
  );
};

export default EmbeddingsOptions;
