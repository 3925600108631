import { Button, Flex, Heading, Divider, Textarea } from "@chakra-ui/react";
import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { JsonBuild, fetchWithTimeout } from "../utils/SettingsUtil";
import TextInput from "./datastore_settings/TextInput";
import YoutubeInput from "./datastore_settings/YoutubeInput";
import InputSettings from "./datastore_settings/InputSettings";

const DataInput = () => {
  const dispatch = useDispatch();

  const store_input_type = useSelector((state) => state.store_input_type);

  const pineconeKey = useSelector((state) => state.pineconekey);
  const openaiKey = useSelector((state) => state.openaikey);
  const cohereKey = useSelector((state) => state.coherekey);
  const storeProvider = useSelector((state) => state.store_provider);
  const storeIndexName = useSelector((state) => state.store_index_name);
  const storeNameSpace = useSelector((state) => state.store_namespace);
  const storeEmbeddingsProvider = useSelector(
    (state) => state.store_embeddings_provider
  );
  const storeEmbeddings = useSelector((state) => state.store_embeddings);
  const chunk_settings_method = useSelector(
    (state) => state.chunk_settings_method
  );
  const chunk_settings_overlap = useSelector(
    (state) => state.chunk_settings_overlap
  );
  const chunk_settings_size = useSelector((state) => state.chunk_settings_size);
  const chunk_settings_separator = useSelector(
    (state) => state.chunk_settings_separator
  );
  const data_to_store = useSelector((state) => state.store_data);
  const uuid = useSelector((state) => state.uuid);

  const manual_chunk_max_size = 15000;

  const [loading, setLoading] = useState(false);

  async function handleStoreData(e) {
    e.preventDefault();

    if (data_to_store === "") {
      alert("Input Data to be stored");
      return false;
    }
    if (
      chunk_settings_method === "Manual" &&
      data_to_store.length > manual_chunk_max_size
    ) {
      alert(
        "Size of Chunk too large, Must be under 3000 characters in Manual Mode"
      );
      return false;
    }

    const json_string = JsonBuild(
      {
        datastore_provider: storeProvider,
        datastore_store_input: store_input_type,
        datastore_index_name: storeIndexName,
        datastore_name_space: storeNameSpace,
        embeddings_provider: storeEmbeddingsProvider,
        embeddings_engine_name: storeEmbeddings,
        text_splitter_type: chunk_settings_method,
        text_splitter_separator: chunk_settings_separator,
        text_splitter_size: chunk_settings_size,
        text_splitter_overlap: chunk_settings_overlap,
        texts: data_to_store,
        openai_api_key: openaiKey,
        pinecone_api_key: pineconeKey,
        cohere_api_key: cohereKey,
        uuid: uuid,
      },
      "data_store"
    );
    if (!json_string) {
      return false;
    }

    setLoading(true);

    // '/docstores/save_data' - localhost
    // process.env.REACT_APP_DATA_STORE_ROUTE - deployment
    var res;
    try {
      res = await fetchWithTimeout(process.env.REACT_APP_DATA_STORE_ROUTE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_JWT_TOKEN,
        },
        body: JSON.stringify(json_string),
      });
    } catch (error) {
      setLoading(false);
      //catch timeout
      if (error.name === "AbortError") {
        alert("Timeout: Request took longer than 30 seconds to respond");
        return false;
      }
    }

    //catch any 500 error before trying to access its JSON response
    if (res.status === 500) {
      setLoading(false);
      alert("Server Unavailable: Please try again later");
      return false;
    }

    const data = await res.json();

    setLoading(false);
    if (res.status != 200) {
      alert(data.detail);
      return false;
    }
    dispatch({ type: "setDataStoreData", store: "" });
    return true;
  }

  function renderInputBox() {
    if (store_input_type === "Text") {
      return <TextInput />;
    } else if (store_input_type === "Youtube") {
      return <YoutubeInput />;
    } else {
      return "Not Supported Input Type";
    }
  }

  return (
    <Flex w="full" h="100vh" flexDirection="column">
      <Heading as="h4" size="md" p="4">
        Store Data
      </Heading>
      <Divider orientation="horizontal" />
      <Flex
        overflowY="auto"
        px={6}
        paddingTop={6}
        flex={1}
        borderLeft="1px"
        borderLeftColor="gray.200"
        flexDirection="column"
      >
        <InputSettings />
        {renderInputBox()}
        <Flex
          pl={3}
          pr={2}
          paddingBottom="10"
          justifyContent="center"
          marginTop={"4"}
        >
          {loading ? (
            <Button bg="pink.600" color="white">
              {" "}
              Storing...{" "}
            </Button>
          ) : (
            <Button bg="pink.600" color="white" onClick={handleStoreData}>
              {" "}
              Store Data{" "}
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DataInput;
