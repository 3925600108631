import {
  Heading,
  Divider,
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  HStack,
} from "@chakra-ui/react";
import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const Settings = () => {
  const dispatch = useDispatch();
  //set input states as user inputs
  const openaiKey = useSelector((state) => state.openaikey);
  const pineconeKey = useSelector((state) => state.pineconekey);
  const cohereKey = useSelector((state) => state.coherekey);

  const handleKeyInput = (e) => {
    if (e.target.id === "openaikey-input") {
      dispatch({
        type: "setOpenAiApiSettings",
        key: document.getElementById("openaikey-input").value,
      });
    }
    if (e.target.id === "pineconekey-input") {
      dispatch({
        type: "setPineconeApiSettings",
        key: document.getElementById("pineconekey-input").value,
      });
    }
    if (e.target.id === "coherekey-input") {
      dispatch({
        type: "setCohereApiSettings",
        key: document.getElementById("coherekey-input").value,
      });
    }
  };
  //show and hide api key
  const [showOpenai, setOpen] = useState(false);
  const [showPinecone, setPine] = useState(false);
  const [showCohere, setCohere] = useState(false);
  const handleApiClick = (e) => {
    if (e.target.id === "openai-button") {
      setOpen(!showOpenai);
    }
    if (e.target.id === "pinecone-button") {
      setPine(!showPinecone);
    }
    if (e.target.id === "cohere-button") {
      setCohere(!showCohere);
    }
  };

  return (
    <Box height="100vh" bg="white" overflowY={"auto"}>
      <Heading as="h4" size="md" p="4">
        API Settings
      </Heading>
      <Divider orientation="horizontal" />
      <FormControl p="6">
        <Heading as="h4" size="sm" marginBottom="2">
          Generator APIs
        </Heading>
        <FormLabel fontSize="14px">OpenAI API Key</FormLabel>
        <HStack>
          <InputGroup>
            <Input
              w="50%"
              type={showOpenai ? "text" : "password"}
              size="sm"
              marginBottom="1"
              borderRadius="8"
              mr={5}
              id="openaikey-input"
              value={openaiKey}
              onChange={handleKeyInput}
            ></Input>
            <Button id="openai-button" size="sm" onClick={handleApiClick}>
              {" "}
              {showOpenai ? "Hide" : "Show"}
            </Button>
          </InputGroup>
        </HStack>
        <FormLabel fontSize="14px">Cohere API Key</FormLabel>
        <HStack>
          <InputGroup>
            <Input
              w="50%"
              type={showCohere ? "text" : "password"}
              size="sm"
              marginBottom="1"
              borderRadius="8"
              mr={5}
              id="coherekey-input"
              value={cohereKey}
              onChange={handleKeyInput}
            ></Input>
            <Button id="cohere-button" size="sm" onClick={handleApiClick}>
              {" "}
              {showCohere ? "Hide" : "Show"}
            </Button>
          </InputGroup>
        </HStack>
        <Heading as="h4" size="sm" marginBottom="2" marginTop="4">
          VectorStore APIs
        </Heading>
        <FormLabel fontSize="14px">Pinecone API Key</FormLabel>
        <HStack>
          <InputGroup>
            <Input
              w="50%"
              type={showPinecone ? "text" : "password"}
              size="sm"
              marginBottom="4"
              borderRadius="8"
              mr={5}
              id="pineconekey-input"
              value={pineconeKey}
              onChange={handleKeyInput}
            ></Input>
            <Button id="pinecone-button" size="sm" onClick={handleApiClick}>
              {" "}
              {showPinecone ? "Hide" : "Show"}
            </Button>
          </InputGroup>
        </HStack>
      </FormControl>
    </Box>
  );
};

export default Settings;
