import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Heading,
  Button,
  Box,
} from "@chakra-ui/react";
import FaissSettings from "./FaissSettings";
import PineconeSettings from "./PineconeSettings";

const DatastoreSettings = ({ provider }) => {
  const render = () => {
    if (provider === "Pinecone") {
      return <PineconeSettings />;
    } else if (provider === "Faiss") {
      return <FaissSettings />;
    }
  };

  return <FormControl>{render()}</FormControl>;
};

export default DatastoreSettings;
