import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Heading,
  Button,
  Box,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import EmbeddingsOptions from "./embeddings/EmbeddingsOptions";
import DatastoreSettings from "./datastore_settings/DatastoreSettings";
import InputSettings from "./datastore_settings/InputSettings";

const DataRetrieveSettings = () => {
  const dispatch = useDispatch();
  //set settings states as user inputs right away
  const store_provider = useSelector((state) => state.store_provider);

  //Handle any settings input changes
  const handleSettingsInput = (e) => {
    if (e.target.id === "data-store-provider") {
      dispatch({
        type: "setDataStoreProviderSettings",
        store: document.getElementById("data-store-provider").value,
      });
    }
  };

  return (
    <FormControl p="6">
      <Heading as="h4" size="sm" marginBottom="2">
        DataStore Settings
      </Heading>
      <FormControl>
        <FormLabel fontSize="14px">Providers</FormLabel>
        <Select
          placeholder="Select Provider"
          size="sm"
          borderRadius="10"
          marginBottom="1"
          id="data-store-provider"
          value={store_provider}
          onChange={handleSettingsInput}
        >
          <option>Pinecone</option>
          <option>Faiss</option>
        </Select>
      </FormControl>
      <DatastoreSettings provider={store_provider} />
    </FormControl>
  );
};

export default DataRetrieveSettings;
