import { React, useState, useEffect, useRef } from "react";
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    useDisclosure,
    Input,
    Textarea,
    FormControl,
    Heading,
    FormLabel,
    Select,
    Text,
    VStack
  } from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import Model from './Model'
import { fetchWithTimeout } from '../../utils/SettingsUtil'
import { JsonBuild } from '../../utils/SettingsUtil'
import NewModelPopover from './NewModelPopover'
import LoginButton from '../auth0/LoginButton'

const ModelList = () => {
    //redux 
    const logged_in_user = useSelector((state)=>state.logged_in_user);

    //request model list from backend
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()

    //call api fetch here?
    const modelList = useSelector((state)=>state.model_list);

    const dispatch = useDispatch()

    async function refreshModelList() {

      if (logged_in_user === null){
        return false;
      }
      
      var res;
    try{
        res = await fetchWithTimeout(process.env.REACT_APP_USER_DATA_GET_MODELS_ROUTE, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": process.env.REACT_APP_JWT_TOKEN
        },
        body: JSON.stringify({user_id:logged_in_user.uid, model_id:'blank'})

      });

    } catch(error) {
        //catch timeout
        if (error.name === 'AbortError')
        {
            alert('Timeout: Request took longer than 30 seconds to respond')
            return false;
        }
    }

    const data = await res.json();
    const modellist = data.data.reverse().map(x => x) 

    dispatch({type: 'setModels', store: modellist})

    }

    return (
      <>
        <Button ref={btnRef} size="xs" onClick={() => {onOpen(); refreshModelList();}}>
          My Models
        </Button>
        <Drawer
          isOpen={isOpen}
          placement='left'
          onClose={onClose}
          finalFocusRef={btnRef}
          size = "xs">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader size = "md">My Models</DrawerHeader>
            {logged_in_user ? (
            <DrawerBody>
              <NewModelPopover refreshModelList={refreshModelList} />
            {modelList.map((variable, index) => (<Model key ={index} variable={variable} refreshModelList={refreshModelList}/> ))}
            </DrawerBody>
            ) : (
              <>
            <Text marginLeft="4" p="2" fontSize={"md"}>You need to be logged in to save, load and share models.</Text>
            <VStack>
              <Button colorScheme={"pink"} size = "md" width = "50%"><LoginButton/></Button>
            </VStack>
            </>
            )}
            <DrawerFooter>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    )
}

export default ModelList;
