import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { React, useState } from "react";

// layouts and pages
import RootLayout from "./components/RootLayout";
import Chat from "./pages/Chat";
import DataStore from "./pages/DataStore";
import Settings from "./pages/Settings";
import Docs from "./pages/Docs";

// router and routes
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Chat />}></Route>
      <Route path="/datastore" element={<DataStore />}></Route>
      <Route path="/settings" element={<Settings />}></Route>
      <Route path="/docs" element={<Docs />}></Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
