import React from "react";
import { auth } from "../../firebase";
import { Button, Text } from "@chakra-ui/react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const LoginButton = () => {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };

  return (
    <Text
      width="100%"
      align="center"
      as="button"
      fontSize="md"
      onClick={signInWithGoogle}
    >
      Sign In
    </Text>
  );
};

export default LoginButton;
